import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const RareSayings = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta
          name="twitter:title"
          content="The Rare Sayings of Lil Shoyaright!"
        />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Rare Sayings of Lil Shoyaright!</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jun. 6, 2022) </p>
        </div>
        <div className="articleBody">
          <p>
            In the previous article I wrote about Twitter in a disparaging way.
            I wanna clear the record and say this: I love Twitter. As far as
            socials go, it’s my only home, my native language. But one of the
            reasons I started publishing here instead is that I think the more
            you constrain the number of characters you can use to express
            yourself, the less complex and fleshed-out your thoughts and ideas
            become in general (I think that’s why threads have become a major
            part of the Twitter vernacular). I aspire to be capable of exploring
            things deeply, because it seems to me that that’s how great things
            come about.
          </p>
          <p>
            All that said, I think brevity is extremely valuable when deployed
            deliberately (i.e. not structurally mandated like on Twitter). Not
            only is it a great rhetorical device, I’d call it an artform in its
            own right. My love of words (link) is really only matched by my love
            of aphorisms.
          </p>
          <p>
            So in that spirit, here go a collection of short thoughts of my own
            (cough unpublished Twitter drafts). Some are poetic, others
            straightforward. Some are irreverent, others as holy as I’m capable
            of being. They appear hidden from view, but{" "}
            <span className="editorsNote">LOOK</span> my tech-savvy loved one,
            and you shall <span className="editorsNote">FIND</span>:
          </p>
          <p className="hidden">When I change my shirt, I change my life.</p>
          <p className="hidden">
            The modern-day equivalent of moving the rabbit ears on the TV
            antenna is refreshing the page on an illegal sports stream that’s
            stuck buffering.
          </p>
          <p className="hidden">
            Some of my high school textbooks had some very good diagrams. Thank
            you, diagrams!!!{" "}
          </p>
          <p className="hidden">
            If I had written “What Does the Fox Say?” I woulda flipped the
            royalties into generational wealth!!!!!
          </p>
          <p className="hidden">
            The image will never mean as much as the meaning of the image.
          </p>
          <p className="hidden">
            If you come around me dressing like it’s the 90’s I may become angry
            and I’m very sorry about that don’t mind me.
          </p>
          <p className="hidden">
            I just made a killing on frozen concentrated orange-juice futures.
          </p>
          <p className="hidden">
            Only a fool holds strong convictions that they didn’t win
            themselves.
          </p>
          <p className="hidden">
            If your politics don’t result in you taking any kind of action in
            the three-dimensional world then what you actually have is
            fantasies, not politics.
          </p>
          <p className="hidden">
            We living in the alternate reality where Sal put some brothers on
            the wall.
          </p>
          <p className="hidden">
            I never mind.
            <br />
            I’m never unkind.
            <br />
            Except to myself.
            <br />
            What a bind!
          </p>
          <p className="hidden">
            If your net worth is less than $100k and you drive anything besides
            a Toyota or a Honda you just might be dumb as shit.
          </p>
          <p className="hidden">On mommies when shit get hard I get harder</p>
          <p className="hidden">
            If you wake up and the elixir of life flows out your faucet, how can
            you not want that for the entire world?
          </p>
          <p className="hidden">
            I know our media glamorizes youth but only clowns fail to realize
            that it’s an honor to grow older.
          </p>
          <p className="hidden">
            If we should converse:
            <br />
            I will keep my ears active, <br />
            And keep yours precious
          </p>
          <p className="hidden">
            It’s crazy to me when people don’t take history seriously. See me
            when I show up on a planet I wanna find out wtf been going on.
          </p>
          <p className="hidden">
            Anger, bliss, sadness, and jubilee, are all just barriers between
            You and Me.
          </p>
          <p>
            Imagine sitting on the bank of a river and finding an ounce of gold
            that washes up beside you. And now imagine putting on a wetsuit,
            goggles, and flippers, and diving into a clear blue sea. At the
            bottom of the sea you find an oyster. You glide back to the surface.
            When you crack open that oyster, you find a perfectly round pearl.
            Which is more valuable? I’ll take the pearl any day.
          </p>
          <p>
            And wait wow I’m only just now realizing the connection between that
            analogy and the concept of a pearl of wisdom. I swear that was
            completely unintentional 🤯 but it fits just right.
          </p>
          <p>
            But yeah naw if you were able to locate the{" "}
            <span className="editorsNote">RARE SAYINGS</span> (hint: double tap
            or click and drag, you'll have to copy and paste on iOS because of{" "}
            <a
              href="https://caniuse.com/?search=%3A%3Aselection"
              target="_blank"
              rel="noopener noreferer"
            >
              {" "}
              this
            </a>{" "}
            smh) and you see me tweet any of em in the future don’t be joe lol,
            just be proud that you saw it here first.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default RareSayings
